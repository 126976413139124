var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "guestRoomTable" },
    [
      _c(
        "el-table",
        {
          staticStyle: { width: "100%" },
          attrs: { data: _vm.roomStatusedList, height: _vm.height },
        },
        [
          _c("el-table-column", {
            attrs: { prop: "roomNumber", label: "房间号" },
          }),
          _c("el-table-column", {
            attrs: { prop: "rent", label: "出租" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    scope.row.rent == "rented"
                      ? _c("span", [_vm._v("是")])
                      : _c("span", [_vm._v("否")]),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "人员识别" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    scope.row.identity == "president"
                      ? _c("span", [_vm._v("总裁卡")])
                      : scope.row.identity == "manage"
                      ? _c("span", [_vm._v("管理卡")])
                      : scope.row.identity == "service"
                      ? _c("span", [_vm._v("服务员卡")])
                      : scope.row.identity == "guest"
                      ? _c("span", [_vm._v("客人卡")])
                      : scope.row.identity == "noPeople"
                      ? _c("span", [_vm._v("无卡")])
                      : _vm._e(),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { prop: "fault", label: "故障" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    scope.row.fault == "true"
                      ? _c("span", [_vm._v("是")])
                      : _c("span", [_vm._v("否")]),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "客房状态" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    scope.row.status == "clears" || scope.row.status == "mur"
                      ? _c("span", [_vm._v("请求清理")])
                      : scope.row.status == "dont" || scope.row.status == "dnd"
                      ? _c("span", [_vm._v("请勿打扰")])
                      : scope.row.status == "soss" || scope.row.status == "sos"
                      ? _c("span", [_vm._v("SOS")])
                      : _c("span", [_vm._v("--")]),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { prop: "optType", label: "操作类型" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    scope.row.optType == "cbk"
                      ? _c("span", [_vm._v("插拔卡")])
                      : scope.row.optType == "mcbj"
                      ? _c("span", [_vm._v("门磁报警")])
                      : scope.row.optType == "fjgz"
                      ? _c("span", [_vm._v("房间故障")])
                      : scope.row.optType == "clears" ||
                        scope.row.optType == "mur"
                      ? _c("span", [_vm._v("请求清理")])
                      : scope.row.optType == "dont" ||
                        scope.row.optType == "dnd"
                      ? _c("span", [_vm._v("请勿打扰")])
                      : scope.row.optType == "soss" ||
                        scope.row.optType == "sos"
                      ? _c("span", [_vm._v("SOS")])
                      : scope.row.optType == "0"
                      ? _c("span", [_vm._v("--")])
                      : _vm._e(),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { prop: "ct", label: "触发时间", width: "140" },
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "page" },
        [
          _c("el-pagination", {
            attrs: {
              background: "",
              "current-page": _vm.page,
              "page-sizes": [10, 20, 50, 100],
              "page-size": _vm.pageSize,
              layout: "prev, pager, next, sizes, total, jumper",
              total: _vm.total,
            },
            on: {
              "size-change": _vm.handleSizeChange,
              "current-change": _vm.handleCurrentChange,
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }