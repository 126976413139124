<template>
  <!-- 客房事件页面 -->
  <!-- 头部选择器 -->
  <div class="my-box">
    <el-card shadow="always" class="my-card topS">
      <el-form label-width="50px">
        <el-row :gutter="10" style="margin-bottom: 0">
          <el-col :span="3">
            <el-form-item label="品牌">
              <el-select v-model="seekData.brandId" @change="selectOne" placeholder="请选择" clearable filterable>
                <el-option v-for="(item, index) in brandSelectData" :key="index" :label="item.brandName"
                  :value="item.id"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="5">
            <el-form-item label="门店">
              <el-select v-model="seekData.hotelId" @change="selectTwo" placeholder="请选择" clearable filterable>
                <el-option v-for="(item, index) in hotelSelectData" :key="index" :label="item.name"
                  :value="item.id"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="4">
            <el-form-item label="建筑">
              <el-select v-model="seekData.buildingId" @change="selectThree" clearable filterable placeholder="请选择">
                <el-option v-for="(item, index) in buildingSelectData" :key="index" :label="item.buildingName"
                  :value="item.id"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="4">
            <el-form-item label="楼层">
              <el-select v-model="seekData.floorId" clearable filterable placeholder="请选择">
                <el-option v-for="(item, index) in floorSelectData" :key="index" :label="item.floorName"
                  :value="item.id"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="4">
            <el-form-item label="房号">
              <el-input placeholder="请输入房号" v-model="seekData.roomNumber" clearable class="my-input"></el-input>
              <!-- @blur="seekData.roomNumber = onlyNumber(seekData.roomNumber)"
                  @clear="clearroomNumber"
              type="number"-->
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="10">
          <el-col :span="3">
            <div class="grid-content">
              <el-button @click="exportExcel" v-if="showexport != -1" :loading="downLoading">导出</el-button>
            </div>
          </el-col>
          <el-col :span="4">
            <el-form-item label="操作类型" label-width="70px">
              <el-select v-model="seekData.optType" clearable filterable placeholder="请选择">
                <el-option :label="item.label" :value="item.value" v-for="item in operType"
                  :key="item.value"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="触发时间" label-width="70px">
              <el-date-picker v-model="value6" type="daterange" range-separator="至" start-placeholder="开始日期"
                end-placeholder="结束日期" format="yyyy 年 MM 月 dd 日" value-format="yyyy-MM-dd"></el-date-picker>
            </el-form-item>
          </el-col>

          <el-col :span="11">
            <div class="grid-content reset-button">
              <el-button type="primary" @click="handleAllSelect">查询</el-button>
              <el-button @click="reset">重置</el-button>
            </div>
          </el-col>
        </el-row>
      </el-form>
      <el-row></el-row>
    </el-card>

    <el-card v-loading="loading">
      <GuestRoomTable :height="height" :roomStatusedList="roomStatusedList" :total="total" :page="seekData.pageNum"
        :pageSize="seekData.pageSize" @changePage="changePage" @changePageSize="changePageSize"></GuestRoomTable>
      <GuestRoomTable :height="height" :roomStatusedList="newList" :total="total" :page="seekData.pageNum"
        :pageSize="seekData.pageSize" @changePage="changePage" @changePageSize="changePageSize" id="out-table"
        style="display: none"></GuestRoomTable>
    </el-card>
  </div>
</template>
<script>
import GuestRoomTable from "@/components/common/GuestRoomTable.vue";
import {
  getRoomStatused,
  getBrandSelect,
  getHotelSelect,
  getRoomTypelSelect,
  getBuildingSelect,
  getFloorSelect,
  roomExport,
} from "@/api";
import { getLongDateTime, showLabel, getDay } from "@/api/common.js";

import { export_json_to_excel } from "@/vendor/Export2Excel.js";
const execlTab = [
  {
    label: "房间号",
    value: "roomNumber",
  },
  {
    label: "出租",
    value: "rent",
    solt_TEXT: "statusList",
  },
  {
    label: "人员识别",
    value: "identity",
    solt_TEXT: "identitySelectData",
  },
  {
    label: "故障",
    value: "fault",
    solt_TEXT: "statusList",
  },
  {
    label: "客房状态",
    value: "status",
    solt_TEXT: "statusType",
    width: 100,
  },
  {
    label: "操作类型",
    value: "optType",
    solt_TEXT: "operType"
  },

  {
    label: "触发时间",
    value: "ct",
  },
];
export default {
  // name:"kfsj",
  data() {
    return {
      height: null,
      selectedOptions: ["zujian", "data", "tag"],
      value6: "",
      roomStatusedList: [],
      newList: [],
      total: 0,
      loading: false,
      // 查询的数据
      seekData: {
        brandId: null,
        hotelId: null,
        buildingId: null,
        floorId: null,
        roomNumber: null,
        status: null,
        optType: null,
        pageNum: 1,
        pageSize: 10,
      },
      brandSelectData: null,
      hotelSelectData: null,
      buildingSelectData: null,
      floorSelectData: null,
      roomTypeSelectData: null,
      statusList: [
        {
          label: "是",
          value: "true",
        },
        {
          label: "否",
          value: "false",
        },
      ],
      statusList: [
        {
          label: "是",
          value: "rented",
        },
        {
          label: "否",
        },
      ],
      statusType: [
        {
          labelName: "请勿打扰",
          label: "请勿打扰",
          value: "dnd",
        },
        {
          labelName: "请即清理",
          label: "请即清理",

          value: "mur",
        },
        {
          labelName: "SOS",
          label: "SOS",
          value: "sos",
        },
        {
          labelName: "无状态",
          label: "--",
          value: "0",
        },
      ],
      // 识别身份
      identitySelectData: [
        {
          value: "president",
          label: "总裁卡",
        },
        {
          value: "manage",
          label: "管理卡",
        },
        {
          value: "service",
          label: "服务员卡",
        },
        {
          value: "guest",
          label: "客人卡",
        },
        {
          value: "noPeople",
          label: "无卡",
        },
      ],
      operType: [
        {
          label: "插拔卡",
          value: "cbk",
        },
        {
          label: "门磁警报",
          value: "mcbj",
        },
        {
          label: "清理操作",
          value: "mur",
        },
        {
          label: "请勿打扰",
          value: "dnd",
        },
        {
          label: "SOS警报",
          value: "sos",
        },
        {
          label: "无操作",
          value: "0",
        },
      ],
      downLoading: false,
    };
  },
  // 注册表格组件
  components: {
    GuestRoomTable,
  },
  beforeMount() {
    let h = document.documentElement.clientHeight || document.body.clientHeight;
    this.height = h - 295;
  },
  methods: {
    onlyNumber(value) {
      if (value != "" && value != undefined) {
        let val = value;
        // let t = val.charAt(0);
        // val = val.replace(/[^\d\.]/g, '');
        // val = val.replace(/^\./g, '');
        // val = val.replace(/\.{2,}/g, '.');
        let newValue = val
          .toString()
          .replace(".", "$#$")
          .replace(/\./g, "")
          .replace("$#$", ".");
        return parseInt(newValue == "" ? 0 : newValue);
      } else return 0;
    },

    clearroomNumber() {
      this.seekData.roomNumber = null;
    },

    // 导出 excel
    exportExcel(i = 0) {
      this.downLoading = true;
      if (this.loading) {
        if (i < 5) {
          setTimeout(() => {
            this.exportExcel(i);
          }, 1000);
        } else {
          this.$$message.warning("请稍后再试");
        }
      } else {
        if (!this.roomStatusedList || this.roomStatusedList.length == 0) {
          this.$$message.warning("未加载到数据");
          return;
        }
        let tHeader = [];
        let filterVal = [];
        execlTab.forEach((v) => {
          tHeader.push(v.label);
          filterVal.push(v.value);
        });
        let list = this.getExeclData();
        let data = this.formatJson(filterVal, list);
        setTimeout(() => {
          export_json_to_excel(tHeader, data, "客房事件信息");
          this.downLoading = false;
        }, 500);
      }

      // roomExport().then((res) => {
      //   console.log(res);
      //   var blob = new Blob([res.data], {
      //     type: "application/vnd.ms-excel",
      //   });
      //   var downloadElement = document.createElement("a");
      //   var href = window.URL.createObjectURL(blob); // 创建下载的链接
      //   downloadElement.href = href;
      //   downloadElement.download = "客房事件信息" + ".xlsx"; // 下载后文件名
      //   document.body.appendChild(downloadElement);
      //   downloadElement.click(); // 点击下载
      //   document.body.removeChild(downloadElement); // 下载完成移除元素
      //   window.URL.revokeObjectURL(href); // 释放掉blob对象
      // });
    },
    // d导出execl数据转换
    getExeclData() {
      let arr = new Array();
      this.roomStatusedList.forEach((v) => {
        let obj = Object.assign({}, v);
        obj.identity = showLabel(v.identity, this.identitySelectData);
        obj.rent = v.rent == "rented" ? "是" : "否";
        // showLabel(v.rent, this.statusList);
        obj.fault = v.fault == "true" ? "是" : "否";
        // showLabel(v.fault, this.statusList);
        obj.status = showLabel(v.status, this.statusType);
        arr.push(obj);
      });
      console.log(arr);
      return arr;
    },
    formatJson(filterVal, jsonData) {
      return jsonData.map((v) => filterVal.map((j) => v[j]));
    },

    /* 初始化下拉框 */
    // 获取品牌下拉框 -- 1
    initialize() {
      getBrandSelect().then((res) => {
        if (res.status === 200) {
          this.brandSelectData = res.data.data;
        }
      });
    },
    // 获取门店下拉框 -- 2
    selectOne(id) {
      this.seekData.hotelId = null;
      this.seekData.buildingId = null;
      this.seekData.floorId = null;
      this.seekData.roomNumber = null;
      this.hotelSelectData = null;
      this.buildingSelectData = null;
      this.floorSelectData = null;
      getHotelSelect(id).then((res) => {
        if (res.data.data) {
          this.hotelSelectData = res.data.data;
        } else {
          this.$message({
            message: "该品牌下没有门店数据",
            type: "warning",
          });
        }
      });
      // 获取房间类型下拉框
      getRoomTypelSelect(id).then((res) => {
        if (res.status === 200) {
          this.roomTypeSelectData = res.data.data;
        }
      });
    },
    // 获取建筑下拉框  -- 3
    selectTwo(id) {
      this.seekData.floorId = null;
      this.seekData.roomNumber = null;
      this.buildingSelectData = null;
      this.floorSelectData = null;
      getBuildingSelect(id).then((res) => {
        this.seekData.buildingId = null;
        if (res.data.data.length > 0) {
          this.buildingSelectData = res.data.data;
          this.seekData.buildingId = res.data.data[0].id;
          this.selectThree(this.seekData.buildingId);
        } else {
          this.$message({
            message: "该门店下没有建筑数据",
            type: "warning",
          });
        }
      });
    },
    // 获取楼层下拉框 -- 4
    selectThree(id) {
      getFloorSelect(id).then((res) => {
        if (res.data.data.length > 0) {
          this.floorSelectData = res.data.data;
        } else {
          this.$message({
            message: "该建筑下没有楼层数据",
            type: "warning",
          });
        }
      });
    },
    handleAllSelect() {
      let start = null;
      let end = null;
      this.seekData.pageNum = 1;
      if (this.value6 != "" && this.value6 != null) {
        this.value6.forEach((item, index) => {
          if (index == 0) {
            // start = new Date(item).getTime()
            // start = item + " 00:00:00"
            start = item;
          } else if (index == 1) {
            // end = new Date(item).getTime()
            // end = item + " 23:59:59"
            end = item;
          }
        });
        this.seekData.start = start + " 00:00:00";
        this.seekData.end = end + " 23:59:59";
      } else {
        this.seekData.start = null;
        this.seekData.end = null;
      }
      this.seekData.roomNumber == "" ? null : this.seekData.roomNumber;
      this.getList(this.seekData);
    },
    reset() {
      this.seekData.brandId =
        this.seekData.hotelId =
        this.seekData.buildingId =
        this.seekData.floorId =
        this.seekData.roomNumber =
        this.seekData.status =
        this.seekData.optType =
        null;
      this.hotelSelectData =
        this.buildingSelectData =
        this.floorSelectData =
        null;
      this.seekData.start = this.seekData.end = null;
      this.value6 = "";
      this.seekData.pageNum = 1;
      this.getList(this.seekData);
    },
    getList(obj) {
      this.loading = true;
      getRoomStatused(obj)
        .then((res) => {
          this.loading = false;

          this.roomStatusedList = res.data.data.rows;
          this.total = res.data.data.total;
          //  if (res.data.data.total) {
          //   this.getAllList(res.data.data.total);
          //  }
        })
        .catch((err) => {
          this.loading = false;
        });
      // }
    },
    // getAllList(val) {
    //  // const selectData = Object.assign({ pageNum: this.seekData.pageNum, pageSize: val }, this.seekData)
    //  const selectData = JSON.parse(JSON.stringify(this.seekData));
    //  selectData.pageSize = val;
    //  // getRoomStatused(selectData).then(res => {
    //  //   this.newList = res.data.rows
    //  //   this.total = res.data.total
    //  // })
    // },
    changePage(val) {
      this.seekData.pageNum = val;
      this.getList(this.seekData);
    },
    changePageSize(val) {
      this.seekData.pageSize = val;
      this.seekData.pageNum = 1;
      this.getList(this.seekData);
    },
  },
  computed: {
    showexport: function () {
      return this.userJurisdiction.indexOf("roomStatused:export");
    },
  },
  created() {
    this.userJurisdiction = this.$store.state.roleMenu;
    //this.getList(this.seekData);
    this.initialize();
  },
};
</script>
<style lang="scss">
.my-box {
  .my-card {
    margin-bottom: 10px;
  }

  .el-row {
    margin-bottom: 10px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  .el-col {
    border-radius: 4px;
  }

  .grid-content {
    border-radius: 4px;
    height: 36px;
    white-space: nowrap;

    .el-input__inner {
      max-width: 190px;
    }

    span {
      font-size: 14px;
    }
  }
}
</style>

<style>
.chooseRoom .el-cascader {
  width: calc(100% - 234px);
}
</style>
