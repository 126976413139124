<template>
  <div class="guestRoomTable">
    <el-table :data="roomStatusedList" :height="height" style="width: 100%">
      <el-table-column prop="roomNumber" label="房间号"></el-table-column>
      <el-table-column prop="rent" label="出租">
        <template slot-scope="scope">
          <span v-if="scope.row.rent == 'rented'">是</span>
          <span v-else>否</span>
          <!-- <i class="el-icon-check" v-if='scope.row.rent'></i>
          <i class="el-icon-close" v-else></i> -->
        </template>
      </el-table-column>
      <el-table-column label="人员识别">
        <template slot-scope="scope">
          <span v-if="scope.row.identity == 'president'">总裁卡</span>
          <span v-else-if="scope.row.identity == 'manage'">管理卡</span>
          <span v-else-if="scope.row.identity == 'service'">服务员卡</span>
          <span v-else-if="scope.row.identity == 'guest'">客人卡</span>
          <span v-else-if="scope.row.identity == 'noPeople'">无卡</span>
        </template>
      </el-table-column>
      <!-- <el-table-column prop="doorWarning" label="门磁报警">
    <template slot-scope="scope">
     <span v-if='scope.row.doorWarning == "true"'>是</span>
     <span v-else>否</span>
>
    </template>
   </el-table-column> -->
      <el-table-column prop="fault" label="故障">
        <template slot-scope="scope">
          <span v-if="scope.row.fault == 'true'">是</span>
          <span v-else>否</span>
          <!-- <i class="el-icon-warning" v-if='scope.row.fault== "true"'></i> -->
          <!-- <i class="el-icon-close" v-else></i> -->
        </template>
      </el-table-column>
      <el-table-column label="客房状态">
        <template slot-scope="scope">
          <span v-if="scope.row.status == 'clears' || scope.row.status == 'mur'">请求清理</span>
          <span v-else-if="scope.row.status == 'dont' || scope.row.status == 'dnd'">请勿打扰</span>
          <span v-else-if="scope.row.status == 'soss' || scope.row.status == 'sos'">SOS</span>
          <span v-else>--</span>
        </template>
      </el-table-column>
      <el-table-column prop="optType" label="操作类型">
        <template slot-scope="scope">
          <span v-if="scope.row.optType == 'cbk'">插拔卡</span>
          <span v-else-if="scope.row.optType == 'mcbj'">门磁报警</span>
          <span v-else-if="scope.row.optType == 'fjgz'">房间故障</span>
          <span v-else-if="scope.row.optType == 'clears' || scope.row.optType == 'mur'">请求清理</span>
          <span v-else-if="scope.row.optType == 'dont' || scope.row.optType == 'dnd'">请勿打扰</span>
          <span v-else-if="scope.row.optType == 'soss' || scope.row.optType == 'sos'">SOS</span>
          <span v-else-if="scope.row.optType == '0'">--</span>
        </template>
      </el-table-column>
      <el-table-column prop="ct" label="触发时间" width="140">
      </el-table-column>
    </el-table>
    <div class="page">
      <el-pagination
        background
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="page"
        :page-sizes="[10, 20, 50, 100]"
        :page-size="pageSize"
        layout="prev, pager, next, sizes, total, jumper"
        :total="total"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    roomStatusedList: {
      type: Array,
    },
    total: {
      type: Number,
      default: 0,
    },
    page: {
      type: Number,
      default: 1,
    },
    pageSize: {
      type: Number,
      default: 10,
    },
    height: {
      type: Number,
      default: 500,
    },
  },
  data() {
    return {
      tableData: [],
    };
  },
  methods: {
    handleSizeChange(val) {
      this.$emit("changePageSize", val);
    },
    handleCurrentChange(val) {
      this.$emit("changePage", val);
    },
  },
};
</script>

<style lang="scss">
.guestRoomTable {
  i.el-icon-check {
    font-size: 20px;
    color: #67c23a;
  }
  i.el-icon-close {
    font-size: 20px;
    color: #f56c6c;
  }
  .page {
    display: inline-flex;
    flex-direction: row;
    justify-content: flex-start;
    width: 100%;
  }
}
</style>